import { Post } from '../http/httpVerbs';

export const apiGetToken = async (id) => {
    try {
        const response = await Post(`${process.env.REACT_APP_API_URL}/creators/generateToken/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
};
