import React from 'react';
import { Box, Typography } from '@mui/material';

export const TitleFL = ({
    color,
    title,
    titleSize = 'h5',
    iconTitle,
    subtitle,
    subtitleSize = 'body2',
    iconSubtitle,
    center = false,
}) => {
    const styles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: center ? 'center' : 'flex-start',
        textAlign: center ? 'center' : 'left',
        mb: 1,
    };

    const textStyles = {
        wordBreak: 'break-word',
        whiteSpace: 'normal',
        overflowWrap: 'break-word',
    };

    return (
        <Box
            sx={{
                color,
                textAlign: 'center',
            }}
        >
            <Box sx={styles}>
                <Box sx={{ marginRight: 0.5 }}>{iconTitle && iconTitle}</Box>
                <Typography variant={titleSize} sx={textStyles} letterSpacing={1}>
                    {title}
                </Typography>
            </Box>
            <Box sx={styles}>
                <Box sx={{ marginRight: 0.5 }}>{iconSubtitle && iconSubtitle}</Box>
                {subtitle && (
                    <Typography variant={subtitleSize} sx={textStyles}>
                        {subtitle}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};
