import React, { useState, useEffect } from 'react';
import { ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { formatDate } from '../../utils';
import { useMobileDetect } from '../../hooks';
import { AvatarFL } from '../ui/AvatarFL';

export const ChatsMensagem = ({ chat, isMyMessage }) => {
    const isMobile = useMobileDetect();
    const { nomeChat, tipoChat } = chat;
    const [bgColor, setBgcolor] = useState('');

    const getBgColorByUser = (chatType) => {
        switch (chatType) {
            case 1:
                setBgcolor('var(--grey-50-color)');
                break;
            case 2:
                setBgcolor('var(--primary-10-color)');
                break;
            case 3:
                setBgcolor('var(--blue-color)');
                break;
        }
    };

    const ChatHeader = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', fontSize: '0.9rem' }}>
                <span>{nomeChat}</span>
                <span style={{ fontSize: '0.7rem' }}>{formatDate(chat.created_at)}</span>
            </div>
        );
    };

    useEffect(() => {
        getBgColorByUser(tipoChat);
    }, []);

    return (
        <>
            <ListItem
                sx={{
                    display: 'flex',
                    marginBottom: 1,
                    borderRadius: 1,
                    bgcolor: bgColor,
                    overflowY: 'auto',
                    width: isMobile ? '85%' : '75%',
                }}
            >
                {!isMyMessage && (
                    <ListItemAvatar sx={{ margin: 0 }}>
                        <AvatarFL
                            name={nomeChat}
                            width={30}
                            height={30}
                            color="var(--primary-dark-color)"
                            fontSize={14}
                        />
                    </ListItemAvatar>
                )}
                <ListItemText
                    primary={<ChatHeader />}
                    secondary={<span dangerouslySetInnerHTML={{ __html: chat.mensagem }} />}
                />
            </ListItem>
        </>
    );
};
