import React, { useEffect } from 'react';
import { Routes } from './routes';
import { useAuthStore } from './store/useAuthStore';
import { useNotificationStore } from './store/useNotificationStore';
import { ErrorProvider, useErrorStore } from './store/useErrorStore';

export function App() {
    const fetchCreator = useAuthStore((state) => state.fetchCreator);
    const fetchNotifications = useNotificationStore((state) => state.fetchNotifications);
    const showError = useErrorStore((state) => state.showError);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchCreator();
                await fetchNotifications();
            } catch (error) {
                showError(error);
            }
        };

        fetchData();
    }, [fetchCreator, fetchNotifications, showError]);

    return (
        <ErrorProvider>
            <Routes />
        </ErrorProvider>
    );
}
