import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

export const CardHeader = ({ title }) => {
    return (
        <Box
            sx={{
                margin: 1,
                padding: 2,
                color: 'var(--primary-dark-color)',
            }}
        >
            <Typography variant="h5" letterSpacing={1}>
                {title}
            </Typography>
            <Divider sx={{ bgcolor: 'var(--primary-dark-color)' }} aria-hidden="true" />
        </Box>
    );
};
