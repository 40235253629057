import React from 'react';
import { Box } from '@mui/material';
import { Chats } from '../chat/Chats';
import { GenericModal } from '../ui/Modal';

export const ChatModal = ({ item, openChat, toggleDrawer, contexto, headerTitle }) => {
    return (
        <GenericModal open={openChat} onClose={toggleDrawer} bgColor={'var(--white-color)'}>
            <Box>
                <Chats hasHeader headerTitle={headerTitle} title="Mensagens" item={item} contexto={contexto} />
            </Box>
        </GenericModal>
    );
};
