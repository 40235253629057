import { Post } from '../http/httpVerbs';

export const apiResetPassword = async (email) => {
    try {
        const response = await Post(`${process.env.REACT_APP_API_URL}/creators/resetPassword`, { email });
        return response;
    } catch (error) {
        throw error;
    }
};
