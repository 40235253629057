export const generateLink = async ({ contexto, idcampanha }) => {
    let link = '';
    let contextoAbrir = '';

    switch (contexto) {
        case 'ADMIN_CREATOR_CAMPANHAITEM':
            link = `/campanhas/${idcampanha}`;
            contextoAbrir = 'ADMIN_CREATOR_CAMPANHAITEM';
            break;
        case 'ADMIN_CLIENTE_CAMPANHAITEM':
            link = `/campanhas/${idcampanha}`;
            contextoAbrir = 'ADMIN_CLIENTE_CAMPANHAITEM';
            break;
        case 'ADMIN_CREATOR_CAMPANHA':
            link = `/campanhas/${idcampanha}`;
            contextoAbrir = 'ADMIN_CREATOR_CAMPANHA';
            break;
        case 'ADMIN_CLIENTE_CAMPANHA':
            link = `/campanhas/${idcampanha}`;
            contextoAbrir = 'ADMIN_CLIENTE_CAMPANHA';
            break;
        default:
            break;
    }

    return link;
};
