import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { DashboardBottomBar } from './DashboardBottomBar';
import { useMobileDetect } from '../../hooks';
import { Login } from '../login';
import './dashboard.css';
import { Header } from '../../components/ui/Header';
import { DashboardDrawerMenu } from '../../components/ui/DashboardDrawerMenu';
import { useAuthStore } from '../../store/useAuthStore';
import { apiGetCreators } from '../../services/apiGetCreators';

export const Dashboard = ({ children }) => {
    const creator = useAuthStore((state) => state.creator);
    const updateCreator = useAuthStore((state) => state.updateCreator);
    const isMobile = useMobileDetect();

    const [open, setOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkHashAndFetchCreator = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const hash = urlParams.get('hash');

            if (hash) {
                try {
                    const decodedData = atob(hash);
                    const [email, validade] = decodedData.split('|');
                    const agora = new Date();

                    if (new Date(validade) > agora) {
                        const response = await apiGetCreators(email);
                        const creatorData = response[0];

                        if (creatorData) {
                            updateCreator(creatorData);

                            urlParams.delete('hash');

                            window.location.href = '/editUserInfo';
                        }
                    }
                } catch (error) {
                    console.log({ error });
                } finally {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        };

        if (!creator) {
            checkHashAndFetchCreator();
        } else {
            setIsLoading(false);
        }
    }, [creator, updateCreator]);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    if (isLoading) {
        return <div>Carregando...</div>; // Exibe enquanto está carregando
    }

    if (!creator) {
        return <Login />;
    }

    return (
        <>
            {isMobile && <Header />}
            <Box className="dash-container">
                {!isMobile && <DashboardDrawerMenu toggleDrawer={toggleDrawer} open={open} />}

                <Box
                    component="main"
                    sx={{
                        backgroundColor: 'var(--grey-100-color)',
                        width: '100%',
                        overflowX: 'hidden',
                        flex: 1,
                    }}
                >
                    {children}
                </Box>

                {isMobile && <DashboardBottomBar />}
            </Box>
        </>
    );
};
