import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { useAuthStore } from '../../../store/useAuthStore';
import logo from '../../../_assets/logo.png';
import { LanguageSelector } from '../LanguageSelector';
import { ProfileMenu } from '../ProfileMenu';
import { AvatarFL } from '../AvatarFL';
import './header.css';
import { NotificationMenu } from '../../NotificationMenu';

export const Header = () => {
    const creator = useAuthStore((state) => state.creator);

    const [openDrawerMobile, setOpenDrawerMobile] = React.useState(false);

    const toggleDrawerMobile = (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenDrawerMobile(!openDrawerMobile);
    };

    return (
        <AppBar position="static" className="header">
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <div className="containerLogo">
                    <img src={logo} className="logo" alt="logo" />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', paddingRight: '2%' }}>
                    {creator && <NotificationMenu />}
                    {creator && <AvatarFL name={creator?.nome} onClick={toggleDrawerMobile} />}
                    {!creator && <LanguageSelector />}
                </div>
            </Toolbar>
            <ProfileMenu openDrawer={openDrawerMobile} toggleDrawer={toggleDrawerMobile} creator={creator} />
        </AppBar>
    );
};
