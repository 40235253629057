export const identifyFileType = (url) => {
    const extension = url?.split('.')?.pop()?.toLowerCase();

    const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv'];
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
    const documentExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'odt', 'ods', 'odp'];

    if (videoExtensions?.includes(extension)) {
        return 'video';
    } else if (imageExtensions?.includes(extension)) {
        return 'image';
    } else if (documentExtensions?.includes(extension)) {
        return 'document';
    } else {
        return 'unknown';
    }
};
