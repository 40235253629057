import { Get } from '../http/httpVerbs';

export const apiGetCreators = async (query) => {
    try {
        const response = await Get(`${process.env.REACT_APP_API_URL}/creators?query=${query}`);
        return response;
    } catch (error) {
        throw error;
    }
};
