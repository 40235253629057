import { IcampanhasItensStatus } from '../data/Interfaces';

export function isDisabled(status) {
    return !(
        status == '' ||
        status == null ||
        status == IcampanhasItensStatus.WAITING ||
        status == IcampanhasItensStatus.WAITING_CLIENT ||
        status == IcampanhasItensStatus.REPROVED ||
        status == IcampanhasItensStatus.REPROVED_CLIENT
    );
}
