import { Box, Modal, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useMobileDetect } from '../../hooks';

export const GenericModal = ({ children, open, onClose, goBack }) => {
    const isMobile = useMobileDetect();

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: isMobile ? '80%' : '35%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'var(--grey-100-color)',
        pt: 3,
        px: 3,
        py: 4,
        color: 'var(--primary-dark-color)',
        borderRadius: 2,
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    };

    return (
        <Modal open={open} onClose={onClose} sx={{ borderRadius: 3 }}>
            <Box sx={modalStyle}>
                {onClose && (
                    <IconButton onClick={onClose} sx={{ float: 'right', color: 'var(--primary-dark-color)' }}>
                        <Close />
                    </IconButton>
                )}

                <Box>{children}</Box>
            </Box>
        </Modal>
    );
};
