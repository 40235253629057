import React from 'react';
import { Typography, Box } from '@mui/material';
import { ChatsMensagem } from './ChatsMensagem';
import { useAuthStore } from '../../store/useAuthStore';

const ChatsMensagens = ({ chats }) => {
    const creator = useAuthStore((state) => state.creator);

    return (
        <>
            {chats?.length > 0 ? (
                chats?.map((chat, index) => {
                    const { tipoChat } = chat;
                    const isMyMessage = tipoChat == 2 && creator.id == chat.idcreator && !chat.idusuario;
                    return (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: isMyMessage ? 'flex-end' : 'flex-start',
                                }}
                            >
                                <ChatsMensagem key={index} chat={chat} isMyMessage={isMyMessage} />
                            </Box>
                        </>
                    );
                })
            ) : (
                <Typography variant="caption">Não há mensagem</Typography>
            )}
        </>
    );
};

export default ChatsMensagens;
