import { FormControl, OutlinedInput, Alert } from '@mui/material';
import { useState } from 'react';
import { ButtonFL } from '../ui/ButtonFL';

export const ChatsFormulario = ({ onSave }) => {
    const [mensagem, setMensagem] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const handleMensagem = () => {
        if (mensagem === '') {
            setShowAlert(true);
        } else {
            setShowAlert(false);
            onSave(mensagem);
            setMensagem('');
        }
    };

    return (
        <>
            {showAlert && <Alert severity="error">Preencha o campo mensagem.</Alert>}
            <FormControl fullWidth variant="outlined" margin="normal">
                <OutlinedInput
                    multiline
                    minRows={2}
                    name="mensagem"
                    id="mensagem"
                    value={mensagem}
                    onChange={(e) => setMensagem(e.target.value)}
                    label="mensagem"
                    required
                    placeholder="Menssagem..."
                />
            </FormControl>

            {mensagem && mensagem.length > 0 && (
                <ButtonFL onClick={handleMensagem} keyPrefix="chats" textKey="sendMessage" />
            )}
        </>
    );
};
