const color = 'var(--white-color)';

export const TextfieldBorderStyle = {
    '& .MuiOutlinedInput-root, & .MuiOutlinedInput-input': {
        '& fieldset': {
            borderColor: color,
        },
        '&.Mui-focused fieldset': {
            borderColor: color,
            '&:hover': {
                borderColor: color,
            },
        },
        '&.Mui-disabled fieldset': {
            borderColor: color,
            color,
        },
    },
    '& .MuiInputLabel-root, & .MuiInputBase-input, & .MuiInputBase-input:disabled': {
        color,
    },
};
