import DvrOutlinedIcon from '@mui/icons-material/DvrOutlined';
import HomeIcon from '@mui/icons-material/Home';

export const menus = [
    {
        title: 'Home',
        link: '/',
        accessLevel: 0,
        icon: HomeIcon,
    },
    {
        title: 'Campanhas',
        link: '/campanhas',
        accessLevel: 0,
        icon: DvrOutlinedIcon,
    },
];
