import React from 'react';
import { IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export const BackButtonFL = ({ handleGoBack }) => {
    return (
        <>
            <IconButton onClick={handleGoBack} sx={{ mt: 1, mx: 2, color: 'var(--primary-color)' }}>
                <ArrowBackIosNewIcon />
            </IconButton>
        </>
    );
};
