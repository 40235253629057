export const getStatusCampain = (value) => {
    let statusCampain = {};

    if (value === 0) {
        statusCampain.color = 'var(--grey-400-color)';
        statusCampain.status = 'Aguardando';
    } else if (value < 100) {
        statusCampain.color = 'var(--warning-color)';
        statusCampain.status = 'Em andamento';
    } else {
        statusCampain.color = 'var(--success-color)';
        statusCampain.status = 'Concluído';
    }

    return statusCampain;
};

export const getStatusStages = (statusText) => {
    const statusMap = {
        COMPLETED: { text: 'COMPLETED', color: 'var(--success-color)' },
        REPROVED: { text: 'REPROVED', color: 'var(--error-color)' },
        REPROVED_CLIENT: { text: 'REPROVED_CLIENT', color: 'var(--error-color)' },
        WAITING: { text: 'WAITING_FOR_APPROVAL', color: 'var(--warning-color)' },
        WAITING_CLIENT: { text: 'WAITING_FOR_CLIENTE_APPROVAL', color: 'var(--warning-color)' },
    };

    for (const status in statusMap) {
        if (statusMap[status].text === statusText) {
            return statusMap[status];
        }
    }

    return statusMap[status];
};
