import React, { useEffect, useRef, useState } from 'react';
import { Box, CardHeader, Divider } from '@mui/material';
import { ChatsFormulario } from './ChatsFormulario';
import ChatsMensagens from './ChatsMensagens';
import { apiPostChat } from '../../services/apiPostChat';
import { useNotificationStore } from '../../store/useNotificationStore';
import { useAuthStore } from '../../store/useAuthStore';
import { useErrorStore } from '../../store/useErrorStore';
import { Get, Post } from '../../http/httpVerbs';
import { Loader } from '../ui/Loader';
import { AvatarFL } from '../ui/AvatarFL';
import { TitleFL } from '../ui/TitleFL';

export const Chats = ({ item, contexto, title, iconTitle, hasHeader, headerTitle }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [updated, setUpdated] = useState(false);
    const [chats, setChats] = useState([]);

    const creator = useAuthStore((state) => state.creator);
    const fetchNotifications = useNotificationStore((state) => state.fetchNotifications);
    const showError = useErrorStore((state) => state.showError);

    const messagesContainerRef = useRef(null);

    const handleSaveChat = async (mensagem) => {
        try {
            setIsLoading(true);
            const chat = {
                mensagem: mensagem,
                idcreator: creator?.id,
                idusuario: null,
                contexto,
            };

            if (contexto == 'ADMIN_CREATOR_CAMPANHAITEM' || contexto == 'ADMIN_CLIENTE_CAMPANHAITEM') {
                chat.idcampanhaitem = item?.id;
            }

            if (contexto == 'ADMIN_CREATOR_CAMPANHA' || contexto == 'ADMIN_CLIENTE_CAMPANHA') {
                chat.idcampanha = item?.id;
            }

            await apiPostChat(chat);

            setUpdated((prevUpdated) => !prevUpdated);
            setIsLoading(false);
        } catch (error) {
            showError(error);
        }
    };

    const handleGetRead = async () => {
        try {
            await Post(`${process.env.REACT_APP_API_URL}/chat/lido/creators`, {
                chats,
            });

            fetchNotifications();
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                let filter = {};

                if (contexto == 'ADMIN_CREATOR_CAMPANHAITEM' || contexto == 'ADMIN_CLIENTE_CAMPANHAITEM') {
                    filter.idcampanhaitem = item?.id;
                }

                if (contexto == 'ADMIN_CREATOR_CAMPANHA' || contexto == 'ADMIN_CLIENTE_CAMPANHA') {
                    filter.idcampanha = item?.id;
                }

                if (creator) filter.idcreator = creator?.id;

                filter.contexto = contexto;

                const queryString = Object.entries(filter)
                    .map(([key, value]) => `${key}=${value}`)
                    .join('&');

                const dataChats = await Get(
                    `${process.env.REACT_APP_API_URL}/chat/?${queryString}&sort=created_at_asc`,
                );

                setChats(dataChats);
            } catch (error) {
                showError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [item, updated]);

    useEffect(() => {
        const scrollToBottom = () => {
            if (messagesContainerRef.current) {
                messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
            }
        };

        const checkChatsAndRead = async () => {
            if (chats && chats.length > 0) {
                await handleGetRead();
            }
        };

        scrollToBottom();
        checkChatsAndRead();
    }, [chats]);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box sx={{ bgcolor: 'var(--white-color)', borderRadius: 2, p: 2 }}>
                    {hasHeader && (
                        <Box sx={{ marginBottom: 4 }}>
                            <CardHeader
                                avatar={<AvatarFL name={headerTitle} width={30} height={30} fontSize={12} />}
                                title={headerTitle}
                                titleTypographyProps={{ fontSize: 16 }}
                            />
                            <Divider />
                        </Box>
                    )}

                    {title && (
                        <Box sx={{ marginBottom: 2 }}>
                            <TitleFL titleSize="body1" title={title} iconTitle={iconTitle} />
                        </Box>
                    )}

                    <Box ref={messagesContainerRef} sx={{ maxHeight: '30vh', overflow: 'auto' }}>
                        <ChatsMensagens chats={chats} />
                    </Box>
                    <Box>
                        <ChatsFormulario onSave={handleSaveChat} />
                    </Box>
                </Box>
            )}
        </>
    );
};
