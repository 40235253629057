import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { getStatusCampain } from '../../../utils';
import { useMobileDetect } from '../../../hooks';

export function ProgressBar({ value }) {
    const isMobile = useMobileDetect();

    const { color, status } = getStatusCampain(value);

    const linearProgressBar = () => (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress
                    variant="determinate"
                    value={value}
                    sx={{
                        width: 160,
                        height: 15,
                        borderRadius: 5,
                        backgroundColor: color,
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: color,
                        },
                    }}
                />
            </Box>

            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="button"
                    color="text.secondary"
                    sx={{
                        fontWeight: '900',
                        lineHeight: 1,
                        color: 'var(--primary-dark-color)',
                        fontSize: '0.775rem',
                    }}
                >{`${Math.round(value)}%`}</Typography>
            </Box>
        </Box>
    );

    const circularProgressBar = () => (
        <>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress
                    variant="determinate"
                    sx={{
                        color,
                    }}
                    thickness={5}
                    size={50}
                    value={100}
                />
                <CircularProgress
                    variant="determinate"
                    sx={{
                        color: color,
                        position: 'absolute',
                        left: 0,
                    }}
                    thickness={5}
                    size={50}
                    value={value}
                />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{
                            fontWeight: '900',
                            lineHeight: 1,
                            color: 'var(--primary-dark-color)',
                            fontSize: '0.775rem',
                        }}
                    >
                        {`${Math.round(value)}%`}
                    </Typography>
                </Box>
            </Box>
        </>
    );

    return <>{isMobile ? circularProgressBar() : linearProgressBar()}</>;
}
