import { Post } from '../http/httpVerbs';

export const apiLogin = async (username, password) => {
    try {
        const response = await Post(`${process.env.REACT_APP_API_URL}/creators/login`, { email: username, password });
        return response;
    } catch (error) {
        throw error;
    }
};
