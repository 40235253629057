import { create } from 'zustand';
import { apiGetUnread } from '../services/apiGetUnread';

export const useNotificationStore = create((set, get) => ({
    notifications: [],

    fetchNotifications: async () => {
        const unreadChats = await apiGetUnread();
        set({ notifications: unreadChats });
    },

    getNotificationsByCampanhaItem: (campanhaItem) => {
        const { notifications } = get();
        const filteredNotifications = notifications.filter((n) => n.idcampanhaitem === campanhaItem);
        return filteredNotifications.length;
    },

    getNotificationsByContext: (contexto) => {
        const { notifications } = get();
        return notifications.filter((n) => n.contexto == contexto).length;
    },

    clearNotifications: () => set({ notifications: [] }),
}));
