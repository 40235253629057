import React, { Suspense, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Box, Container, Fab, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Chat, ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Get } from '../../http/httpVerbs';
import { Loader } from '../ui/Loader';
import { AccordionFL } from '../ui/AccordionFL';
import { CampaignCardsColumn } from '../ui/CampaignCardsColumn';
import { capitalizerFirstLetter } from '../../utils';
import { DescriptionFL } from '../ui/DescriptionFL';
import { TitleFL } from '../ui/TitleFL';
import { ChatModal } from '../ChatModal';
import { useAuthStore } from '../../store/useAuthStore';
import { useErrorStore } from '../../store/useErrorStore';
import { useNotificationStore } from '../../store/useNotificationStore';
import { handleClearStorage, handleOpenCampanhaFromNotification } from '../../utils/handleStorage.utils';
import { useMobileDetect } from '../../hooks';

export const Campanha = () => {
    const creator = useAuthStore((state) => state.creator);
    const { t } = useTranslation('translation', { keyPrefix: 'campanha' });
    const { id } = useParams();

    const isMobile = useMobileDetect();
    const containerRef = useRef(null);

    const [campanha, setCampanha] = useState({});
    const [campanhaItens, setCampanhaItens] = useState([]);
    const [workflow, setWorkflow] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [update, setUpdate] = useState(false);
    const [chatModalOpen, setChatModalOpen] = useState(false);
    const campainTitle = capitalizerFirstLetter(campanha?.titulo);
    const [campainNotifications, setCampainNotifications] = useState(0);
    const { notifications, getNotificationsByContext } = useNotificationStore((state) => ({
        notifications: state.notifications,
        getNotificationsByContext: state.getNotificationsByContext,
    }));

    const showError = useErrorStore((state) => state.showError);

    const description =
        'Todas as suas tarefas dessa campanha começam na lista da esquerda, e sua missão é fazê-las chegar na lista de Concluídos. Para isso, clique em cada um dos cards das tarefas e siga as instruções de cada etapa. No caso de dúvidas, use o chat na página interna do card para falar com a gente.';

    const handleChatButtonClick = () => {
        setChatModalOpen(true);
    };

    const handleCloseChat = () => {
        setChatModalOpen(false);
    };

    const handleScrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
    };

    const handleScrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
    };

    const renderStage = (stage) => {
        const ComponenteEtapa = React.lazy(() => import(`./stages/${stage.etapa}/index`));

        return (
            <CampaignCardsColumn workflow={workflow} keyTitle={stage.etapaTitulo} t={t}>
                <Suspense fallback={<Loader />}>
                    <ComponenteEtapa
                        campanha={campanha}
                        itens={campanhaItens?.filter((cI) => cI.etapa == stage.etapa)}
                        setUpdate={setUpdate}
                    />
                </Suspense>
            </CampaignCardsColumn>
        );
    };

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const dataWorkflows = await Get(`${process.env.REACT_APP_API_URL}/workflows`);

            if (!Array.isArray(dataWorkflows)) {
                setIsLoading(false);
                return;
            }

            if (id && creator) {
                const dataCampanha = await Get(`${process.env.REACT_APP_API_URL}/campanhas/${id}`);
                const dataCampanhaItens = await Get(
                    `${process.env.REACT_APP_API_URL}/campanhasItens?campanha=${id}&creator=${creator.id}`,
                );

                if (dataCampanha) {
                    setCampanha(dataCampanha);
                    const workflowSelected = dataWorkflows.find((wl) => wl.id === dataCampanha.workflow);
                    setWorkflow(workflowSelected);
                }

                if (dataCampanhaItens) {
                    setCampanhaItens(dataCampanhaItens);
                }

                setCampainNotifications(getNotificationsByContext('ADMIN_CREATOR_CAMPANHA'));
            }
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const effect = async () => {
            await fetchData();
        };

        effect();

        if (handleOpenCampanhaFromNotification()) {
            handleChatButtonClick();
            handleClearStorage();
        }
    }, [id, creator, update]);

    useEffect(() => {
        setCampainNotifications(getNotificationsByContext('ADMIN_CREATOR_CAMPANHA'));
    }, [notifications]);

    return (
        <>
            {isLoading && <Loader />}
            <Container maxWidth="xl" disableGutters={true} sx={{ m: 1 }}>
                <Box sx={{ width: '100%' }}>
                    <Box
                        sx={{
                            mx: 2,
                            my: 2,
                            p: 3,
                            bgcolor: 'var(--white-color)',
                            borderRadius: 2,
                        }}
                    >
                        {!isLoading && (
                            <Box sx={{ marginBottom: 2 }}>
                                <TitleFL title={campainTitle} titleSize="h4" color={'var(--primary-dark-color)'} />
                            </Box>
                        )}
                        <Box>
                            <DescriptionFL text={description} color={'var(--primary-dark-color)'} />
                        </Box>
                    </Box>
                    <Box sx={{ mx: 2, my: 2 }}>
                        <AccordionFL title="Briefing da campanha" description={campanha.briefing} />
                    </Box>

                    {!isMobile && (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2, mr: 1 }}>
                            <IconButton onClick={handleScrollLeft}>
                                <ChevronLeft />
                            </IconButton>
                            <IconButton onClick={handleScrollRight}>
                                <ChevronRight />
                            </IconButton>
                        </Box>
                    )}
                    <Box className="containerWorkflow" ref={containerRef}>
                        {workflow?.etapas
                            ?.sort((a, b) => a.id - b.id)
                            ?.map((stage, index) => renderStage(stage, index))}
                    </Box>
                </Box>
            </Container>

            <ChatModal
                headerTitle={creator.nome}
                item={campanha}
                openChat={chatModalOpen}
                toggleDrawer={handleCloseChat}
                contexto="ADMIN_CREATOR_CAMPANHA"
            />

            <Fab
                color="info"
                aria-label="chat"
                sx={{ position: 'fixed', bottom: 30, right: 30, backgroundColor: 'var(--primary-color)' }}
                onClick={handleChatButtonClick}
            >
                <Badge badgeContent={campainNotifications} color="secondary">
                    <Chat />
                </Badge>
            </Fab>
        </>
    );
};
