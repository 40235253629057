export const mensagemVazio = {
    variant: '',
    message: '',
};

export const showMessage = ({ variant, message }, setMessage) => {
    setMessage({ variant, message });

    setTimeout(() => {
        setMessage(mensagemVazio);
    }, 10000);
};

export const verticaisMercadoLivre = [
    'Calçados, Roupas e Bolsas',
    'Casa, Móveis e Decoração',
    'Esportes e Fitness',
    'Beleza e Cuidado Pessoal',
    'Celulares e Telefones',
    'Eletrodomésticos',
    'Eletrônicos, Áudio e Vídeo',
    'Ferramentas',
    'Informática',
    'Brinquedos e Hobbies',
    'Outros',
];

export const IcampanhasItensStatus = {
    WAITING: 'WAITING_FOR_APPROVAL',
    REPROVED: 'REPROVED',

    APPROVED: 'APPROVED',

    WAITING_CLIENT: 'WAITING_FOR_CLIENTE_APPROVAL',
    REPROVED_CLIENT: 'REPROVED_CLIENT',

    APPROVED_CLIENT: 'APPROVED_CLIENT',

    COMPLETED: 'COMPLETED',

    CANCELED: 'CANCELED',
};
