export const storageContexto = sessionStorage.getItem('storageContexto');
export const storageCampanha = sessionStorage.getItem('storageCampanha');
export const storageCampanhaItem = sessionStorage.getItem('storageCampanhaItem');
export const storageCreator = sessionStorage.getItem('storageCreator');
export const storageUsuario = sessionStorage.getItem('storageUsuario');
export const storageCliente = sessionStorage.getItem('storageCliente');

export const isValidStorageValue = (value) => value && value != 'null';

const hasStorage =
    isValidStorageValue(storageContexto) ||
    isValidStorageValue(storageCampanha) ||
    isValidStorageValue(storageCampanhaItem) ||
    isValidStorageValue(storageCreator) ||
    isValidStorageValue(storageUsuario) ||
    isValidStorageValue(storageCliente);

export const setSessionStorage = ({
    contextoAbrir,
    idcampanha,
    idcampanhaitem,
    idcreator,
    idusuario,
    idclienteusuario,
}) => {
    sessionStorage.setItem('storageContexto', contextoAbrir);
    sessionStorage.setItem('storageCampanha', idcampanha);
    sessionStorage.setItem('storageCampanhaItem', idcampanhaitem);
    sessionStorage.setItem('storageCreator', idcreator);
    sessionStorage.setItem('storageUsuario', idusuario);
    sessionStorage.setItem('storageCliente', idclienteusuario);
};

export const handleOpenCampanhaFromNotification = () => {
    return hasStorage && storageContexto == 'ADMIN_CREATOR_CAMPANHA' && storageCreator && storageCampanha;
};

export const handleOpenCampanhaItemFromNotification = () => {
    return hasStorage && storageContexto == 'ADMIN_CREATOR_CAMPANHAITEM' && storageCreator && storageCampanhaItem;
};

export const handleClearStorage = () => {
    sessionStorage.removeItem('storageContexto');
    sessionStorage.removeItem('storageCampanha');
    sessionStorage.removeItem('storageCampanhaItem');
    sessionStorage.removeItem('storageCreator');
    sessionStorage.removeItem('storageUsuario');
    sessionStorage.removeItem('storageCliente');
};
