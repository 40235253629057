import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Grid from '@mui/material/Grid';
import { menus } from '../../data/Menus';
import { useAuthStore } from '../../store/useAuthStore';
import { useMobileDetect } from '../../hooks';

export const ListItems = ({ open }) => {
    const isMobile = useMobileDetect();
    return (
        <List
            component="nav"
            sx={{
                display: 'flex',
                flexDirection: { xs: 'row', md: 'column' },
                color: 'var(--white-color)',
            }}
        >
            {menus.map((menu, index) => (
                <ListItemButton
                    key={index}
                    to={menu.link}
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        py: 1.5,
                        '&:hover': {
                            backgroundColor: 'var(--primary-color)',
                        },
                    }}
                >
                    {menu.icon && (
                        <ListItemIcon title={menu.title} sx={{ color: 'var(--white-color)', minWidth: 'auto' }}>
                            <menu.icon />
                        </ListItemIcon>
                    )}
                    {(open || isMobile) && <ListItemText primary={menu.title} sx={{ textAlign: 'center' }} />}
                </ListItemButton>
            ))}
        </List>
    );
};
