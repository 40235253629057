import React from 'react';
import { Box, Typography, Container, Paper } from '@mui/material';
import { ButtonFL } from '../../components/ui/ButtonFL';

export const OmniContent = () => {
    return (
        <Container
            disableGutters
            sx={{
                m: { xs: 0, md: 5 },
                color: 'var(--primary-color)',
            }}
        >
            <Box mt={{ xs: 4, md: 8 }}>
                <Typography
                    variant="h4"
                    component="h1"
                    gutterBottom
                    sx={{
                        fontSize: { xs: '2rem', md: '3rem' },
                    }}
                >
                    OMNICONTENT
                </Typography>
                <Paper
                    elevation={3}
                    sx={{
                        p: { xs: 1, md: 4 },
                        color: 'var(--primary-dark-color)',
                        boxShadow: 'none',
                    }}
                >
                    <Typography variant="body1" gutterBottom sx={{ py: { xs: 1, md: 2 } }}>
                        Estamos super animados em ter você por aqui!
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ py: { xs: 1, md: 2 } }}>
                        OmniContent é o lugar perfeito para criadores de conteúdo.
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ py: { xs: 1, md: 2 } }}>
                        Aqui, a criatividade é o que manda e as conexões são a nossa força. Queremos que você se sinta
                        em casa e que suas ideias ganhem asas!
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ py: { xs: 1, md: 2 } }}>
                        Fique ligado, porque temos várias novidades incríveis a caminho, além de interações e
                        oportunidades que vão dar aquele empurrãozinho na sua jornada criativa e na sua carreira como
                        criador de conteúdo.
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ py: { xs: 1, md: 2 } }}>
                        Explore, experimente e mergulhe de cabeça nessa comunidade cheia de gente apaixonada por
                        conteúdo.
                    </Typography>
                    <Typography variant="body1" sx={{ py: { xs: 1, md: 2 } }}>
                        Bem-vindo à sua nova casa criativa! Mal podemos esperar para ver o que vamos criar juntos.
                    </Typography>
                </Paper>
                <Box
                    mt={4}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Box sx={{ width: 700 }}>
                        <ButtonFL
                            textKey="completeYourRegistration"
                            keyPrefix="signUp"
                            component="Link"
                            to={'/editUserInfo'}
                        />
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};
