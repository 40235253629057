import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ListItemButton, ListItemText, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Get } from '../../http/httpVerbs';
import { ProgressBar } from '../ui/progressBar';
import { IcampanhasItensStatus } from '../../data/Interfaces';
import { useMobileDetect } from '../../hooks';
import { capitalizerFirstLetter, getStatusCampain } from '../../utils';
import { ButtonFL } from '../ui/ButtonFL';
import { useErrorStore } from '../../store/useErrorStore';
import './campanhas.css';

export const CampanhaItem = ({ campanha, creator }) => {
    const isMobile = useMobileDetect();
    const { t } = useTranslation('translation', { keyPrefix: 'campanhas' });
    const showError = useErrorStore((state) => state.showError);
    const [porcentagem, setPorcentagem] = useState(0);

    const statusCampain = getStatusCampain(porcentagem);

    const campainTitle = capitalizerFirstLetter(campanha?.titulo);
    const clientName = capitalizerFirstLetter(campanha?.clienteNome);

    async function calculaProgresso(campanha) {
        try {
            if (!campanha?.id || !creator.id) return 0;
            let porcentagem = 0;
            const campanhasItens = await Get(
                `${process.env.REACT_APP_API_URL}/campanhasItens?campanha=${campanha?.id}&creator=${creator.id}&sortid_asc`,
            );

            for (let campanhaItem of campanhasItens) {
                if (campanhaItem?.status === IcampanhasItensStatus.COMPLETED)
                    porcentagem += 100 / campanhasItens?.length;
            }

            return porcentagem;
        } catch (error) {
            showError(error);
        }
    }

    useEffect(() => {
        const calcular = async () => {
            const porcentagemCalculada = await calculaProgresso(campanha);
            setPorcentagem(porcentagemCalculada);
        };
        calcular();
    }, [campanha, creator]);

    return (
        <ListItemButton className="containerListItem" to={`/campanhas/${campanha.id}`} component={Link}>
            <Box className="containerCampain">
                <Box className="wrapper firstColunm">
                    {isMobile && (
                        <Typography
                            align="left"
                            variant="caption"
                            sx={{ fontSize: '0.6rem', color: 'var(--grey-700-color)' }}
                        >
                            Campanha:
                        </Typography>
                    )}
                    <ListItemText
                        className="titleCampain cell"
                        primary={campainTitle}
                        sx={{ fontFamily: 'var(--font-family-bold)' }}
                    />
                    {isMobile && (
                        <Typography
                            align="left"
                            variant="caption"
                            sx={{ fontSize: '0.6rem', color: 'var(--grey-700-color)' }}
                        >
                            Cliente:
                        </Typography>
                    )}
                    <ListItemText className="cell" primary={clientName} />
                </Box>
                <Box className="wrapper secondyColumn">
                    <ProgressBar value={porcentagem} t={t} />
                </Box>
                {!isMobile && (
                    <Box sx={{ width: '180px', textAlign: 'center', fontSize: '0.675rem' }}>
                        <ButtonFL
                            component={Link}
                            textKey="seeCampain"
                            keyPrefix="campanhas"
                            to={`/campanhas/${campanha.id}`}
                        />
                    </Box>
                )}
            </Box>
        </ListItemButton>
    );
};
