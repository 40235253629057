import React from 'react';
import { Typography, Box } from '@mui/material';

export const DescriptionFL = ({ text, color, align }) => {
    return (
        <>
            <Box sx={{ marginBottom: 2 }}>
                <Typography align={align} variant="body1" sx={{ color: color, fontSize: '1rem' }}>
                    {text}
                </Typography>
            </Box>
        </>
    );
};
