import React from 'react';
import { Accordion, Box, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TitleFL } from '../../ui/TitleFL';
import './accordion.css';

export const AccordionFL = ({ title, description, icon, color = 'var(--primary-dark-color)' }) => {
    return (
        <Accordion className="accordionFL" sx={{ bgcolor: 'var(--white-color)', color: color }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                <TitleFL title={title} iconTitle={icon} />
            </AccordionSummary>
            <AccordionDetails>
                <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                    {description}
                </Typography>
            </AccordionDetails>
        </Accordion>
    );
};
