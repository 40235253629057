import * as React from 'react';
import { Route, Routes as RouterRoutes, useLocation } from 'react-router-dom';

import { Dashboard } from '../pages/dashboard/Dashboard';
import { Campanha } from '../components/campanhas/Campanha';
import { Campanhas } from '../components/campanhas/Campanhas';
import { ForgotPassword } from '../pages/forgotPassword';
import { Onboarding } from '../pages/onboarding';
import { CreateNewPassword } from '../pages/createNewPassword';
import { EditUserInfo } from '../pages/editUserInfo';

export const Routes = () => {
    const { pathname: URL } = useLocation();
    const token = URL.substring(URL.lastIndexOf('/') + 1);

    return (
        <>
            <RouterRoutes>
                <Route
                    path={'/'}
                    element={
                        <Dashboard>
                            <Onboarding />
                        </Dashboard>
                    }
                />
                <Route
                    path={'/campanhas'}
                    element={
                        <Dashboard>
                            <Campanhas />
                        </Dashboard>
                    }
                />
                <Route
                    path="/campanhas/:id"
                    element={
                        <Dashboard>
                            <Campanha />
                        </Dashboard>
                    }
                />

                <Route
                    path={`/editUserInfo`}
                    element={
                        <Dashboard>
                            <EditUserInfo />
                        </Dashboard>
                    }
                />
                <Route path="/forgotPassword" element={<ForgotPassword />} />
                <Route path="/createNewPassword" element={<CreateNewPassword />} />
                <Route path={`createNewPassword/${token}`} element={<CreateNewPassword />} />
            </RouterRoutes>
        </>
    );
};
