import { useEffect, useState } from 'react';

import { Avatar, Box, Grid, TextField, Typography, IconButton } from '@mui/material';

import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { mensagemVazio, showMessage } from '../../data/Interfaces';
import { Loader } from '../../components/ui/Loader';
import { Message } from '../../components/ui/Message';

import { Header } from '../../components/ui/Header';
import { ButtonFL } from '../../components/ui/ButtonFL';
import { BackButtonFL } from '../../components/ui/BackButtonFL';
import { apiResetPassword } from '../../services';
import { alertColor, removeSpacesFromString, emailValidator } from '../../utils';

export const ForgotPassword = () => {
    const navigator = useNavigate();
    const [message, setMessage] = useState(mensagemVazio);
    const [isLoading, setIsLoading] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [email, setEmail] = useState('');

    const { t } = useTranslation('translation', { keyPrefix: 'forgotPassword' });

    const { message: messageAlert, variant } = message;

    const showMessageAlert = (alertColor, message) => {
        showMessage(
            {
                variant: alertColor,
                message,
            },
            setMessage,
        );
    };

    const onChange = (event) => {
        setEmail(event.target.value);
        validateEmail(event.target.value);
    };

    const validateEmail = (value) => {
        const isEmail = emailValidator(value);
        setButtonDisabled(!isEmail);
    };

    const handleSubmit = async (event) => {
        setIsLoading(true);
        event.preventDefault();

        try {
            const data = new FormData(event.currentTarget);

            const email = removeSpacesFromString(data.get('email'));

            const { message } = await apiResetPassword(email);

            if (message) {
                showMessageAlert(alertColor.success, message);
                setButtonDisabled(true);
            }

            setIsLoading(false);
        } catch (error) {
            showMessageAlert(alertColor.error, error.message);
            setButtonDisabled(true);
            setIsLoading(false);
        } finally {
            setEmail('');
        }
    };

    const handleGoBack = () => {
        navigator(-1);
    };

    useEffect(() => {
        setIsLoading(false);
    }, []);

    return (
        <>
            {isLoading && <Loader />}
            <Header />
            <BackButtonFL handleGoBack={handleGoBack} />
            {messageAlert && <Message variant={variant} message={messageAlert} />}
            <Grid
                container
                sx={{
                    height: '85vh',
                    backgroundColor: 'var(--white-color)',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Grid item xs={10} sm={10} md={10} elevation={8}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Avatar
                            sx={{
                                m: 1,
                                bgcolor: 'var(--primary-color)',
                            }}
                        >
                            <LockResetOutlinedIcon />
                        </Avatar>
                        <Typography variant="h4">{t('title')}</Typography>

                        <Box sx={{ marginTop: 3, width: '40vh', textAlign: 'center' }}>
                            <Typography variant="body1" sx={{ color: 'var(--grey-600-color)' }}>
                                {t('description')}
                            </Typography>
                        </Box>

                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="email"
                                label={'Email'} //TODO: inserir no i18n
                                type="text"
                                id="email"
                                onChange={onChange}
                                value={email}
                            />
                            <ButtonFL
                                textKey="sendEmail"
                                buttonDisabled={buttonDisabled}
                                keyPrefix="forgotPassword"
                                type="submit"
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};
