import React from 'react';
import { Toolbar } from '@mui/material';
import { ListItems } from '../../components/ui/ListItems';
import './dashboard.css';

export const DashboardBottomBar = () => {
    return (
        <Toolbar className="navBarMobile">
            <ListItems isMobile={true} />
        </Toolbar>
    );
};
